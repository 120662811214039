/**
 * @description 视频弹窗
 */
import React, { useEffect, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import Hls from 'hls.js';
import './style.less';

// 计算时间
const timeFormat: any = (value): any => {
	let result: any = parseInt(value, 10);
	let h: any = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600).toString() : Math.floor(result / 3600);
	let m: any = Math.floor((result / 60) % 60) < 10 ? '0' + Math.floor((result / 60) % 60).toString() : Math.floor((result / 60) % 60);
	let s: any = Math.floor(result % 60) < 10 ? '0' + Math.floor(result % 60).toString() : Math.floor(result % 60);

	let res: any = '';
	if (h !== '00') res += `${h}:`;
	res += `${m}:`;
	res += `${s}`;
	if (res === 'NaN:NaN:NaN') {
		return '00:00';
	}

	return res;
};

export const DialogVideo: any = (props): any => {
	// let timer = null; // 控制台显示定时器
	const [timer, setTimer]: any = useState(null);
	const [videoRef, setVideoRef]: any = useState(null);
	const [progressRef, setProgressRef]: any = useState(null);
	const [progress, setprogress]: any = useState(0);
	const [isProgressTouch, setisProgressTouch]: any = useState(false);
	const [isShowLoading, setisShowLoading]: any = useState(true);
	const [muted, setMuted]: any = useState(false);
	const [paused, setPaused]: any = useState(false);
	const [currentTime, setCurrentTime]: any = useState('00:00');
	const [duration, setDuration]: any = useState('00:00');
	const [isHiddenController, setIsHiddenController]: any = useState(false);
	// 静音和外放
	const mutedChange: any = (): any => {
		if (videoRef.muted) {
			videoRef.muted = false;
		} else {
			videoRef.muted = true;
		}
		setMuted(videoRef.muted);
	};
	// 控制条显示
	const controllerChange: any = useCallback((): any => {
		if (timer) {
			clearTimeout(timer);
			setTimer(null);
		}

		if (isHiddenController) {
			setIsHiddenController(false);
			setTimer(
				setTimeout((): any => {
					setIsHiddenController(true);
					clearTimeout(timer);
					setTimer(null);
				}, 1000 * 5)
			);
		} else {
			setIsHiddenController(true);
		}
	}, [timer, isHiddenController]);
	// 点击全屏
	const fullscreen: any = (): any => {
		if (videoRef.webkitEnterFullScreen) {
			videoRef.webkitEnterFullScreen();
		}
		if (videoRef.mozRequestFullScreen) {
			videoRef.mozRequestFullScreen();
		}
		if (videoRef.webkitRequestFullScreen) {
			videoRef.webkitRequestFullScreen();
		}
		if (videoRef.msRequestFullscreen) {
			videoRef.msRequestFullscreen();
		}
	};
	// 播放和暂停
	const videoStatusChange: any = (): any => {
		if (videoRef.paused) {
			videoRef.play();
		} else {
			videoRef.pause();
		}
		setPaused(videoRef.paused);
	};
	// 进度条和时间
	const videoTimeUpdate: any = (): any => {
		if (isProgressTouch) {
			return;
		}
		if (videoRef.currentTime) {
			setCurrentTime(timeFormat(videoRef.currentTime));
		}
		if (videoRef.duration) {
			setDuration(timeFormat(videoRef.duration));
		}
		setprogress(videoRef.currentTime / videoRef.duration);
	};

	// 视频进度条拖动
	const initProgressBtn: any = (): any => {
		let dragProgress: any = 0;
		// 开始,(拖动视频不暂停)
		// 拖动
		const moveFn: any = (e): any => {
			e.preventDefault();
			e.stopPropagation();
			const touch: any = e.touches ? e.touches[0] : e;
			const diff: any = touch.clientX - progressRef.getBoundingClientRect().left;
			dragProgress = diff / progressRef.clientWidth;
			dragProgress = dragProgress < 0 ? 0 : dragProgress > 1 ? 1 : dragProgress;
			setprogress(dragProgress);
			setCurrentTime(timeFormat(videoRef.duration * dragProgress));
		};
		// 放开按钮
		const endFn: any = (e): any => {
			videoRef.currentTime = videoRef.duration * dragProgress;
			progressRef.removeEventListener('touchmove', moveFn, false);
			progressRef.removeEventListener('touchend', endFn, false);
			setisProgressTouch(false);
			dragProgress = 0;
		};
		const startFn: any = (e): any => {
			progressRef.addEventListener('touchmove', moveFn, false);
			progressRef.addEventListener('touchend', endFn, false);
			setisProgressTouch(true);
		};
		progressRef.addEventListener('touchstart', startFn, false);
	};

	// 自动播放
	useEffect((): any => {
		if (videoRef) {
			let url: any = props.resourceURL;
			if ((url && /\.mp4/.test(url)) || (url && /\.mov/.test(url))) {
				videoRef.src = url;
				videoRef.play();
				if (videoRef.duration) {
					setDuration(timeFormat(videoRef.duration));
				}
			} else if (url && url.includes('.m3u8')) {
				if (videoRef.canPlayType('application/vnd.apple.mpegurl')) {
					videoRef.src = url;
					videoRef.play();
					if (videoRef.duration) {
						setDuration(timeFormat(videoRef.duration));
					}
				} else {
					if (Hls.isSupported()) {
						let hls: any = new Hls();
						hls.loadSource(url);
						hls.attachMedia(videoRef);
						hls.on(Hls.Events.MANIFEST_PARSED, (): any => {
							console.log('可以开始播放了');
						});
						videoRef.play();
						if (videoRef.duration) {
							setDuration(timeFormat(videoRef.duration));
						}
					} else {
						console.log('此浏览器不支持视频流播放');
					}
				}
			} else {
				console.warn('视频地址不存在！！');
			}
		}
		if (progressRef) {
			initProgressBtn();
		}
		controllerChange();
	}, [videoRef, progressRef]);

	return (
		<div className="video-dialog-box">
			{
				<video
					ref={(ref): any => {
						setVideoRef(ref);
					}}
					className={'video-contain'}
					preload="auto"
					loop
					webkit-playsinline="true"
					x5-playsinline="true"
					playsInline={true}
					x-webkit-airplay="true"
					crossOrigin="anonymous"
					x5-video-player-type="h5"
					x5-video-player-fullscreen="true"
					x5-video-orientation="landscape"
					poster={props.cover}
					onClick={controllerChange}
					onTimeUpdate={videoTimeUpdate}
					onCanPlay={(): any => {
						setisShowLoading(false);
					}}
					onWaiting={(): any => {
						setisShowLoading(true);
					}}
					onPlaying={(): any => {
						setPaused(videoRef.paused);
					}}
					onPause={(): any => {
						setPaused(videoRef.paused);
					}}
					onVolumeChange={(): any => {
						setMuted(videoRef.muted);
					}}
				/>
			}
			{
				/** 关闭弹窗操作 */
				<div className={`dialog-close-btn ${isHiddenController || duration === '00:00' ? 'hidden' : ''}`}>
					<i
						className="iconfont-licai iconhei"
						onClick={(): any => {
							if (props.onClose) {
								props.onClose();
							}
						}}
					/>
				</div>
			}
			{isShowLoading && !isProgressTouch && (
				<div className="video-loading">
					<img src="https://img.qlchat.com/qlLive/activity/image/OC4QJTCL-86X8-V9CQ-1647503577649-9DU17B744JMI.png?x-oss-process=image/format,webp" />
				</div>
			)}
			{
				/**拖拽期间时间变化显示 */
				isProgressTouch && (
					<div className="drag-time-bg">
						<div className="drag-time-box">
							<div className="left-time">{currentTime}</div>
							<div className="middle">/</div>
							<div className="right-time">{duration}</div>
						</div>
					</div>
				)
			}
			{
				/**操作栏 */
				<div className={`control-btn-box ${isHiddenController || duration === '00:00' ? 'hidden' : ''}`}>
					<div className="progress-box">
						<div className="progress-current-time">{currentTime}</div>
						<div className="video-progress-contain">
							<div
								className="video-progress-touch"
								ref={(ref): any => {
									setProgressRef(ref);
								}}
							/>
							<div className={'video-progress'} style={{ width: `${progress * 100}%` }}>
								<div className="circle" />
							</div>
						</div>
						<div className="progress-all-time">{duration}</div>
					</div>
					<div className="box-footer">
						<div className="left-btn-list">
							<i className={`iconfont ${paused ? 'iconbofang' : 'iconzanting1'} `} onClick={videoStatusChange} />
							<i className={`iconfont ${muted ? 'iconshengyinguan' : 'iconshengyinkai'}`} onClick={mutedChange} />
						</div>
						<div className="right-btn-list">
							<i className="iconfont iconfangda" onClick={fullscreen} />
						</div>
					</div>
				</div>
			}
		</div>
	);
};

/**
 * @description 弹窗渲染
 */
export const renderDom: any = (components, props): any => {
	let rootEle: any = document.getElementById('dialog-components-body');
	if (!rootEle) {
		rootEle = document.createElement('div');
		rootEle.id = 'dialog-components-body';
		document.body.appendChild(rootEle);
	}
	ReactDOM.render(React.createElement(components, props), rootEle);
};

/**
 * @description 关闭渲染
 */
export const colseDom: any = (): any => {
	let rootEle: any = document.getElementById('dialog-components-body');
	if (rootEle) {
		ReactDOM.render(null, rootEle);
		rootEle.parentNode.removeChild(rootEle);
	}
};


/**
 * @description 开始弹窗
 */
export const playVideoDialog: any = async (props = {}): Promise<any> => {
	// 1. 关闭弹窗操作
	let closeDialog: any = (): any => {
		return;
	};
	closeDialog = (): any => {
		colseDom();
		window.removeEventListener('popstate', closeDialog, null);

		return false;
	};
	// 2.弹窗数据
	const obj: any = {
		// 关闭检测弹窗
		onClose: closeDialog,
	};
	// 3.显示检测弹窗
	renderDom(DialogVideo, { ...obj, ...props });

	// 4.监听物理按钮返回
	window.addEventListener('popstate', closeDialog, false);
	const pushHistory: any = (): any => {
		const state: any = {
			title: 'title',
			url: '#',
		};
		window.history.pushState(state, 'title', '#');
	};
	pushHistory();
};
